.wraperr-box {
  height: 100vh;
  display: flex;
}

.err-box {
  color: grey;
  box-shadow: 0px 48px 100px 0px rgba(17, 12, 46, 0.15);
  width: 60%;
  margin: auto;
  padding: 30px;
  border-radius: 12px;
}

.h2-title-ohno {
  color: #000;
  font-size: 26px;
  font-weight: 600;
}

.ohno-subtitle {
  font-weight: 500;
  padding-top: 15px;
  line-height: 24px;
}

.home-button {
  background-color: #4CAF50;
  border: none;
  color: white;
  padding: 10px 8px;
  text-align: center;
  font-size: 14px;
  cursor: pointer;
  border-radius: 6px;
  display: inline-block;
}