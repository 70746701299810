.complete_step_card {
    position: absolute;
    /* right: 0px; */
    left: 0;
    z-index: 9999;
    bottom: 50px;
}

.steps_btn {
    /* margin-left: 131px;
    margin-bottom: 30px; */
}

.complete_pop {
    /* right: 30px;
    top: 85%; */
    left: 15px;
    top: 78%;
    transform: translate(0%, -60%);
    z-index: 99;
    position: fixed;
}

.heading_card {
    font-size: var(--p-font-size-400);
}

.card_back_color {
    background-color: #F1F1F1;
}

.zero_three {
    background-color: #1F6343;
    border-radius: 4px;
    padding: 4px;
    margin-right: 8px;
    margin-left: -4px;
}

.three_points {
    cursor: pointer;
}

/* lines  */
.line-container {
    display: flex;
    justify-content: space-between;
    width: 200px;
    margin: 7px auto;
}

.line {
    height: 6px;
    width: 45px;
    border-radius: 10px;
    background-color: lightgray;
}

.line.active {
    background-color: green;
}
#install-width{
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  max-width: 100px;
}