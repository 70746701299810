#plan_block {
  padding-left: 30px;
  padding-right: 30px;
}

#pln_basic_block {
  background-color: rgba(242, 242, 242, 1);
  margin-top: 97px;
  width: 300px;
}

#shp_pln_basic_block {
  background-color: rgba(242, 242, 242, 1);
  margin-top: 40px;
  width: 300px;
}

#plan_pg_block {
  margin-top: 39px !important;
  padding-top: 17px !important;
}

.addon-socialwall .Polaris-InlineStack {
  justify-content: space-between;
}

.bs_detail_wdth {
  width: 180px;
}

.black_friday_offer_plan {
  position: absolute;
  background-color: #29845A;
  height: 52px;
  width: 100%;
  max-width: 650px;
  top: 38px;
  right: 46px;
  padding: 6px 18px 8px;
  border-radius: 8px;
}

/* .black_frd_text {
  color: white;
  font-style: italic;
} */


#toppos {
  margin-top: 3px;
}

.icon-refresh {
  animation-name: refresh-spin;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.billed_yearly {
  font-size: 13px;
  font-weight: normal;
}

.perMonth {
  font-size: 13px;
  font-weight: normal;
}

.strikethrough {
  text-decoration: line-through;
  color: gray;
}

.desc-prc-grp {
  font-size: 13px;
  font-weight: normal;
}

.pro_box {
  height: 50px;
}

@media (max-width: 768px) {

  .page-plan-wrap {
    .Polaris-Page .Polaris-ShadowBevel>.Polaris-Box .Polaris-Text--bodyLg {
      text-align: center;
    }

    .Polaris-Text--bodyLg+.Polaris-Box .Polaris-InlineStack {
      justify-content: center;
      flex-wrap: wrap;
    }

    #pln_basic_block {
      margin-top: 24px;
      width: 100%;
      max-width: 100%;
      margin-left: auto;
      margin-right: auto;
    }

    #shp_pln_basic_block {
      margin-top: 24px;
      width: 100%;
      max-width: 100%;
      margin-left: auto;
      margin-right: auto;
    }

    .Polaris-Layout__Section--oneThird {
      flex: 1 1 auto;
    }

    .Polaris-Layout__Section {
      min-width: auto;
      flex: 1 1 auto;
    }

    #plan_pg_block {
      padding-left: 15px;
      padding-right: 15px;
    }

  }

}

@media (max-width: 640px) {

  .card-active-plan .Polaris-Box .Polaris-InlineStack {
    flex-wrap: wrap;
  }

}


@media (max-width: 480px) {

  #plan_block {
    padding: 0px;
  }

}



@keyframes refresh-spin {
  0% {
    transform: rotate(0deg)
  }

  to {
    transform: rotate(1turn)
  }
}