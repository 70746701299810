.loading-wrap {
  display: flex;
  position: absolute;
  width: 100%;
  height: 101%;
  z-index: 1111;
  align-items: center;
  justify-content: center;
  background: #fafaf980;
  position: fixed;
}

.spinner_loading_main_div {
  position: absolute;
  z-index: 999;
}

#progress_main_div .Polaris-Frame-Loading__Level {
  background-color: #8a8a8a;
}

/* .Polaris-Frame__LoadingBar:has(.Polaris-Frame-Loading div[style="transform: scaleX(0.99);"] ){
  display: none;
} */
#progress_main_div .Polaris-Frame-Loading{
  background-color: transparent;
}